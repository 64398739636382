<template>
  <div>
    <v-carousel
      height="calc(100vh - 64px)"
      :hide-delimiters="true"
      v-if="$vuetify.breakpoint.name !== 'xs'"
    >
      <v-carousel-item
        src="@/assets/web/marmol.jpg"
      >
        <v-sheet height="calc(100vh - 64px)" color="transparent">
          <v-row class="fill-height" align="center" justify="center">
            <v-img v-if="lang === 'es'" src="@/assets/web/logo.png" max-width="75vw" width="60vw"></v-img>
            <v-img v-else src="@/assets/web/logo_en.png" max-width="75vw" width="60vw" class="mt--100"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <v-carousel-item
        v-for="(item, i) in items[lang]"
        :key="i"
        :src="item.src"
        contain
      >
      </v-carousel-item>

    </v-carousel>

    <v-row v-else >
      
      <v-col cols="12">
        <v-img src="@/assets/movil/marmol.jpg">
          <v-sheet height="100%" color="transparent">
            <v-row class="fill-height" align="center" justify="center">
              <v-img v-if="lang === 'es'" src="@/assets/web/logo.png" max-width="80vw"></v-img>
              <v-img v-else src="@/assets/web/logo_en.png" max-width="80vw"></v-img>
            </v-row>
          </v-sheet>
        </v-img>
      </v-col>

      <v-col cols="12">
        <v-img
          v-for="(item, i) in itemsMovil[lang]"
          :key="i"
          :src="item.src"
          contain
        >
          <!-- <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="black"
                :width="7"
                :size="70"
              ></v-progress-circular>
            </v-row>
          </template> -->
        </v-img>
      </v-col>

    </v-row>

  </div>
        
</template>

<script>
//web
import img1 from '@/assets/web/hospitium-01.jpg';
import img2 from '@/assets/web/hospitium-02.jpg';
import img3 from '@/assets/web/hospitium-03.jpg';
import img4 from '@/assets/web/hospitium-04.jpg';
import img5 from '@/assets/web/hospitium-05.jpg';
import img6 from '@/assets/web/hospitium-06.jpg';
import img7 from '@/assets/web/hospitium-07.jpg';
import img8 from '@/assets/web/hospitium-08.jpg';
import img9 from '@/assets/web/hospitium-09.jpg';
import img10 from '@/assets/web/hospitium-10.jpg';
import img11 from '@/assets/web/hospitium-11.jpg';
import img12 from '@/assets/web/hospitium-12.jpg';
import img13 from '@/assets/web/hospitium-13.jpg';
import img14 from '@/assets/web/hospitium-14.jpg';
import img15 from '@/assets/web/hospitium-15.jpg';
import img16 from '@/assets/web/hospitium-16.jpg';

import en_img1 from '@/assets/web/en/hospitium-01.jpg';
import en_img2 from '@/assets/web/en/hospitium-02.jpg';
import en_img3 from '@/assets/web/en/hospitium-03.jpg';
import en_img4 from '@/assets/web/en/hospitium-04.jpg';
import en_img5 from '@/assets/web/en/hospitium-05.jpg';
import en_img6 from '@/assets/web/en/hospitium-06.jpg';
import en_img7 from '@/assets/web/en/hospitium-07.jpg';
import en_img8 from '@/assets/web/en/hospitium-08.jpg';
import en_img9 from '@/assets/web/en/hospitium-09.jpg';
import en_img10 from '@/assets/web/en/hospitium-10.jpg';
import en_img11 from '@/assets/web/en/hospitium-11.jpg';
import en_img12 from '@/assets/web/en/hospitium-12.jpg';
import en_img13 from '@/assets/web/en/hospitium-13.jpg';
import en_img14 from '@/assets/web/en/hospitium-14.jpg';
import en_img15 from '@/assets/web/en/hospitium-15.jpg';
import en_img16 from '@/assets/web/en/hospitium-16.jpg';
//movil
import img1_movil from '@/assets/movil/responsive hospitium-01.jpg';
import img2_movil from '@/assets/movil/responsive hospitium-02.jpg';
import img3_movil from '@/assets/movil/responsive hospitium-03.jpg';
import img4_movil from '@/assets/movil/responsive hospitium-04.jpg';
import img5_movil from '@/assets/movil/responsive hospitium-05.jpg';
import img6_movil from '@/assets/movil/responsive hospitium-06.jpg';
import img7_movil from '@/assets/movil/responsive hospitium-07.jpg';
import img8_movil from '@/assets/movil/responsive hospitium-08.jpg';
import img9_movil from '@/assets/movil/responsive hospitium-09.jpg';
import img10_movil from '@/assets/movil/responsive hospitium-10.jpg';
import img11_movil from '@/assets/movil/responsive hospitium-11.jpg';
import img12_movil from '@/assets/movil/responsive hospitium-12.jpg';
import img13_movil from '@/assets/movil/responsive hospitium-13.jpg';
import img14_movil from '@/assets/movil/responsive hospitium-14.jpg';
import img15_movil from '@/assets/movil/responsive hospitium-15.jpg';

import en_img1_movil from '@/assets/movil/en/responsive hospitium-01.jpg';
import en_img2_movil from '@/assets/movil/en/responsive hospitium-02.jpg';
import en_img3_movil from '@/assets/movil/en/responsive hospitium-03.jpg';
import en_img4_movil from '@/assets/movil/en/responsive hospitium-04.jpg';
import en_img5_movil from '@/assets/movil/en/responsive hospitium-05.jpg';
import en_img6_movil from '@/assets/movil/en/responsive hospitium-06.jpg';
import en_img7_movil from '@/assets/movil/en/responsive hospitium-07.jpg';
import en_img8_movil from '@/assets/movil/en/responsive hospitium-08.jpg';
import en_img9_movil from '@/assets/movil/en/responsive hospitium-09.jpg';
import en_img10_movil from '@/assets/movil/en/responsive hospitium-10.jpg';
import en_img11_movil from '@/assets/movil/en/responsive hospitium-11.jpg';
import en_img12_movil from '@/assets/movil/en/responsive hospitium-12.jpg';
import en_img13_movil from '@/assets/movil/en/responsive hospitium-13.jpg';
import en_img14_movil from '@/assets/movil/en/responsive hospitium-14.jpg';
import en_img15_movil from '@/assets/movil/en/responsive hospitium-15.jpg';

export default {
  name: 'Home',
  data() {
    return {
      items: {
        es: [
          { 'src': img1, },
          { 'src': img2, },
          { 'src': img3, },
          { 'src': img4, },
          { 'src': img5, },
          { 'src': img6, },
          { 'src': img7, },
          { 'src': img8, },
          { 'src': img9, },
          { 'src': img10, },
          { 'src': img11, },
          { 'src': img12, },
          { 'src': img13, },
          { 'src': img14, },
          { 'src': img15, },
          { 'src': img16, },
        ],
        en: [
          { 'src': en_img1, },
          { 'src': en_img2, },
          { 'src': en_img3, },
          { 'src': en_img4, },
          { 'src': en_img5, },
          { 'src': en_img6, },
          { 'src': en_img7, },
          { 'src': en_img8, },
          { 'src': en_img9, },
          { 'src': en_img10, },
          { 'src': en_img11, },
          { 'src': en_img12, },
          { 'src': en_img13, },
          { 'src': en_img14, },
          { 'src': en_img15, },
          { 'src': en_img16, },
        ],

      },
      itemsMovil: {
        es: [
          {'src': img1_movil},
          {'src': img2_movil},
          {'src': img3_movil},
          {'src': img4_movil},
          {'src': img5_movil},
          {'src': img6_movil},
          {'src': img7_movil},
          {'src': img8_movil},
          {'src': img9_movil},
          {'src': img10_movil},
          {'src': img11_movil},
          {'src': img12_movil},
          {'src': img13_movil},
          {'src': img14_movil},
          {'src': img15_movil},
        ],
        en: [
          {'src': en_img1_movil},
          {'src': en_img2_movil},
          {'src': en_img3_movil},
          {'src': en_img4_movil},
          {'src': en_img5_movil},
          {'src': en_img6_movil},
          {'src': en_img7_movil},
          {'src': en_img8_movil},
          {'src': en_img9_movil},
          {'src': en_img10_movil},
          {'src': en_img11_movil},
          {'src': en_img12_movil},
          {'src': en_img13_movil},
          {'src': en_img14_movil},
          {'src': en_img15_movil},
        ]
      }
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang
    },
  }
}
</script>

<style scoped>
  .mt--100 {
    margin-top: -15vh;
  }
</style>