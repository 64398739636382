<template>
  <div id="app">
    <v-app>
      <v-app-bar app flat color="white">

        <v-toolbar flat>
            <v-toolbar-items>
              <router-link :to="{name: 'Home'}">
                <v-img src="@/assets/web/icono.png" contain width="60" alt="Hospitium"></v-img>
              </router-link>
            </v-toolbar-items>
            
            <v-spacer></v-spacer>
            
            <v-toolbar-items>

              <v-btn v-show="path !== 'crc'" text :to="{name: 'crc'}">
                <v-img src="@/assets/icono crc.png" contain width="60" alt="CRC" :title="lang === 'es' ? 'Servicio CRC' : 'Service CRC'" />
              </v-btn>
            
              <v-btn v-show="path !== 'Hospitium'" text :to="{name: 'Hospitium'}">
                HOSPITIUM
              </v-btn>

              <v-btn v-show="path !== 'Contacto'" text :to="{name: 'Contacto'}">
                {{ lang === 'es' ? 'Contacto' : 'Contact' }}
              </v-btn>
            
            </v-toolbar-items>
        </v-toolbar>
            
      </v-app-bar>

      <v-main>
        <router-view> </router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'hospitium',
  data() {
    return {
    }
  },
  computed: {
    lang () {
      return this.$route.params.lang
    },
    path () {
      return this.$route.name
    }
  },
}
</script>