import { render, staticRenderFns } from "./Hospitium.vue?vue&type=template&id=537a6074&scoped=true&"
import script from "./Hospitium.vue?vue&type=script&lang=js&"
export * from "./Hospitium.vue?vue&type=script&lang=js&"
import style0 from "./Hospitium.vue?vue&type=style&index=0&id=537a6074&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537a6074",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCarousel,VCarouselItem,VCol,VImg,VRow,VSheet})
